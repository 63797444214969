// Import Bootstrap
@import "bootstrap/bootstrap.scss";

body {
  padding-bottom: 15px;
}
.main-image-container {
  padding-top: 15px;
  padding-bottom: 15px;
}
.main-image {
  width: 400px;
  max-width: 100%;
  height: auto;
}
.header-link {
  margin: auto;
  display: block;
  width: 400px;
  max-width: 100%;
}
.card {
  margin-bottom: 15px;
}
.video-container-no-margin {
  position: relative;
}
.video-container {
  position: relative;
  margin-bottom: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
div.btn {
  cursor: pointer;
}
.links-btn {
  position: relative;
  display: block;
}
.links-dropdown-menu {
  display: none;
  position: absolute;
  top: 38px;
  right: 0;
  width: 250px;
  height: 100px;
}
.links-dropdown-menu .list-group-item {
  border-radius: 0 0 0 0;
}
.svg-icon {
  height: 1rem;
  width: auto;
  fill: #f10000;
  vertical-align: middle;
}

a.btn-outline-dark {
  background-color: #0d0d0d;

  &:not(:disabled):not(.disabled):active, &:hover, &:focus {
    background-color: #f10000;

    .svg-icon {
      fill: #000;
    }

  }
}

$episodeTextColor: #f10000;

div.card {
  background-color: #0d0d0d;
  color: $episodeTextColor;
}

a.episode-title-link {
  color: $episodeTextColor;

  &:hover, &:focus {
    background-color: #f10000;
    color: #000000;
    text-decoration: none;
  }

}

a {
  color: $episodeTextColor;
  &:hover, &:focus, &:active {
    color: $episodeTextColor;
  }
}
.licensed-under-note {
  color: #aaa;
}

.title-header {
  margin-bottom: 0;
}
.date-label {
  margin-bottom: 8px;
}

.rokfin-img-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.rokfin-img {
  width: 500px;
  height: auto;
  max-width: 100%;
}
.patreon-img-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.patreon-img {
  width: 500px;
  height: auto;
  max-width: 100%;
}
